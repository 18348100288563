// About.js
import React from 'react';

const About = () => (
  <div>
    <div className="description">
      <p className="title">Про сайт <span className="name">VoltSkins</span></p>
      <p className="text">
        Добро пожаловать на <span className="name">VoltSkins</span>, мы ваш пункт назначения для новых скинов CS2!
        Наша платформа предлагает захватывающие задания для получения различных скинов CS2.
        После того, как вы выполните задания вы можете выбрать любой скин, который захотите.
        Присоединяйтесь к нам сегодня, выполняйте задания и получайте скины CS2!
      </p>
      {/* <p className="text">
        Наша платформа предлагает захватывающие задания для получения различных скинов CS2.
      </p>
      <p className="text">
        После того, как вы выполните задания вы можете выбрать любой скин, который захотите.
      </p>
      <p className="text">
        Присоединяйтесь к нам сегодня, выполняйте задания и получайте скины CS2!
      </p> */}
    </div>

  </div>
);

export default About;
