// components/Description.js
import React from 'react';

const Description = () => (
   <div className="description">
      <p className="title">Выполняй простые задания и получай награды <span className="name">скинами!</span></p>
      
      <p className="text">
         <span className="b">Нафармить скины проще простого.</span> Присоединяйся к крутому комьюнити Voltskins, выполняй простые задания и получай крутые скины для твоего инвентаря!
      </p>

      <p className="title">
         <span className="b">Как это работает? </span>
      </p>

      <ul className="list">
         <li className="list-item">Создайте аккаунт</li>
         <li className="list-item">Выбирайте задание из списка, которое хотите выполнить</li>
         <li className="list-item">Получайте награду за ваши действия</li>
         <li className="list-item">Забирайте крутые скины, которые так давно хотели</li>
      </ul>
   </div>
);

export default Description;
