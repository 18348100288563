// Privacy.js
import React from 'react';

const Privacy = () => (
  <div className="">
    <div className="description">
      <p className="title">Политика конфиденциальности <span className="name">VoltSkins</span></p>
    </div>
    <p className="text">
      Просим вас ознакомиться с действующими правилами обработки персональных данных посетителей и пользователей сервиса VoltSkins.
    </p>
    <p>
      Под персональными данными понимается информация, используемая для идентификации личности. Предоставляя свои персональные данные, вы соглашаетесь на их хранение и дальнейшую обработку. Эти данные не будут переданы третьим лицам.
    </p>
    <p>
      В процессе регистрации на сайте VoltSkins вы даете согласие на обработку и хранение следующих данных:
    </p>
    <ul className='terms-list'>
      <li>Адреса электронной почты: Необходимы для процедур идентификации пользователя;</li>
      <li>Информация о ваших посещениях сайта, включая IP-адрес, операционную систему, время и дату входа: Эта информация используется исключительно для анализа и поддержания веб-сайта на высоком качественном уровне;</li>
      <li>Информация о файлах cookie: Эти небольшие текстовые файлы генерируются сайтом с помощью вашего браузера и хранятся на вашем устройстве. Они не содержат никаких персональных данных о вас. Сервис использует эти файлы для облегчения использования сайта и более эффективного сбора статистических данных.</li>
    </ul>
    <p>
      Используя сервис VoltSkins, вы автоматически соглашаетесь с условиями, описанными выше. Если у вас есть вопросы относительно обработки личной информации, вы можете связаться с нами по адресу support@volt.skin.
    </p>
  </div>
);

export default Privacy;
